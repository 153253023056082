import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import EditIcon from '@material-ui/icons/Edit';

// Form Components
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from 'formik-material-ui-pickers';
import { Formik, Form, Field } from "formik";
import moment from 'moment';
import {  MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as yup from "yup";

// Custom Components
import {
    ValidatedTextField,
    StyledTableCell,
    StyledTableRow,
    GlobalStatusChip,
    PublishStatusChip
} from "components/widgets/CustomComponent";
import { createURLButton } from "components/widgets/URLButton";
import { reformatDateString } from 'components/Utils';
import { extractDate, isEmpty } from 'components/Utils';
import { useSurveyContext } from 'components/survey/SurveyPanel';

// AWS Imports
import { useAWSLambdaContext } from "components/AWSLambda";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 900,
        height: "100%",
    },
    stepsPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        background: '#e6e6e6',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    div: {
        float: "right",
    }
}
));

export default function SurveyEditForm(props) {
    const {
        survey_id,
        title,
        description,
        priority,
        coin_award,
        start_dt,
        end_dt,
        survey_url,
        is_published,
        is_global,
        closeDialog
    } = props; 
    const classes = useStyles();

    const [formValues, setFormValues] = useState({
        title: title,
        description: description,
        priority: priority,
        coin_award, coin_award,
        start_dt: start_dt,
        end_dt: end_dt,
        survey_url: survey_url
    });
    const [updates, setUpdates] = useState({});
    const [failure, setFailure] = useState('');
    const [success, setSuccess] = useState('');
    const [response, setResponse] = useState('');
    const [activeStep, setActiveStep] = useState(0);

    const { editSurvey } = useAWSLambdaContext();
    const { handleSurveyFetch } = useSurveyContext();

    const handleSurveyEdit = async () => {
        handleNext();
        if ("start_dt" in updates) {
            updates.start_dt = moment(updates.start_dt).format("YYYY/MM/DD[T]HH:mm");
        }
        if ("end_dt" in updates) {
            updates.end_dt = moment(updates.end_dt).format("YYYY/MM/DD[T]HH:mm");
        }
        const {success, message, response, error } = await editSurvey(survey_id, updates);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        handleNext();
    };
    
    const calculateChanges = (data) => {
        var output = {};
        const attributes = ["title", "description", "priority", "coin_award", "survey_url"];
        const old_start_dt = extractDate(start_dt);
        const old_end_dt = extractDate(end_dt);
        const new_start_dt = extractDate(data.start_dt);
        const new_end_dt = extractDate(data.end_dt);

        for (var index in attributes) {
            if (props[attributes[index]] !== data[attributes[index]]) {
                output[attributes[index]] = data[attributes[index]];
            }
        }
        if (old_start_dt !== new_start_dt) {
            output["start_dt"] = data.start_dt;
        }
        if (old_end_dt !== new_end_dt) {
            output["end_dt"] = data.end_dt;
        }
        console.log(output);
        setUpdates(output);
    }


    const getSteps = () => {
        return [<FM id="app.survey.edit_survey" />, <FM id="app.general.confirm_changes" />, <FM id="app.general.result" />];
    }

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleFinish = () => {
        handleSurveyFetch();
        closeDialog();
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return createInputStep();
            case 1:
                return createConfirmStep();
            case 2:
                return createLoadingStep();
            case 3:
                return createResultStep();
            default:
                return 'Unknown Step';
        }
    }

    const validationSchema = yup.object({
        survey_id: yup
            .string(),
        title: yup
            .string(),
        description: yup
            .string(),
        priority: yup
            .number(),
        coin_award: yup
            .number(),
        start_dt: yup
            .string(),
        end_dt: yup
            .string()
            .nullable(),
        survey_url: yup
            .string()
            .url()
    })

    const createInputStep = () => {
        return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={formValues}
                    onSubmit={(data) => {
                        calculateChanges(data);
                        setFormValues(data);
                        handleNext();
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_id" />} name="survey_id" type="input" disabled defaultValue={survey_id}/>
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_title" />} name="title" type="input" />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_description" />} name="description" type="input" multiline rows={2} />
                            </Grid>
                            <Grid item xs={4}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.priority" />} name="priority" type="number" />
                            </Grid>
                            <Grid item xs={4}>
                                <Paper variant='outlined' className={classes.paper}>
                                    <GlobalStatusChip is_global={is_global} />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper variant='outlined' className={classes.paper}>
                                    <PublishStatusChip is_published={is_published}/>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Field 
                                    component={DateTimePicker}
                                    fullWidth
                                    autoOk
                                    disableToolbar 
                                    label={<FM id="app.survey.attributes.start_dt" />}
                                    name="start_dt" 
                                    margin="dense"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd - HH:mm"
                                    ampm={false}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field 
                                    fullWidth
                                    component={DateTimePicker}
                                    disableToolbar
                                    autoOk
                                    label={<FM id="app.survey.attributes.end_dt" />}
                                    name="end_dt" 
                                    margin="dense"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd - HH:mm"
                                    ampm={false}
                                    minDate={values.start_dt}
                                    minDateMessage="End Date cannot be before Start Date."
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_url" />} name="survey_url" type="input" />
                            </Grid>
                            <Grid item xs={4}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.coin_award" />} name="coin_award" type="number" />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button className={classes.button} onClick={closeDialog} color="default" variant="outlined" disableElevation size="large">
                                <FM id="app.general.cancel_button" />
                            </Button>
                            <Button className={classes.button} type="submit" color="primary" variant="contained" disableElevation size="large" >
                                <FM id="app.general.verify_button" />
                            </Button>
                        </DialogActions>
                    </Form>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        );
    }

    

    const createConfirmStep = () => {
        const is_empty = isEmpty(updates);
        const createRow = (attribute_name, old_value, new_value) => {
            return {attribute_name, old_value, new_value};
        };
        
        const rows = [createRow(<FM id="app.survey.attributes.survey_id" />, survey_id, survey_id)]

        if ("title" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.survey_title" />, title, updates.title));
        };
        if ("description" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.survey_description" />, description, updates.description));
        };
        if ("priority" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.priority" />, priority, updates.priority));
        };
        if ("start_dt" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.start_dt" />, reformatDateString(start_dt), reformatDateString(updates.start_dt)));
        };
        if ("end_dt" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.end_dt" />, reformatDateString(end_dt), reformatDateString(updates.end_dt)));
        };
        if ("survey_url" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.image_url" />, createURLButton(survey_url), createURLButton(updates.survey_url)));
        };
        if ("coin_award" in updates) {
            rows.push(createRow(<FM id="app.survey.attributes.coin_award" />, coin_award, updates.coin_award));
        };
        return(
            <div>
                { is_empty ?
                <Paper variant='outlined' className={classes.paper}>
                    <Typography>
                        <FM id="app.general.no_changes_detected" />
                    </Typography>
                </Paper> :
                <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><FM id="app.general.attribute_name" /></StyledTableCell>
                                <StyledTableCell align="right"><FM id="app.general.old_value" /></StyledTableCell>
                                <StyledTableCell align="right"><FM id="app.general.new_value" /></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                            <StyledTableRow key={row.attribute_name}>
                                <StyledTableCell component="th" scope="row">
                                    <b>{row.attribute_name}</b>
                                </StyledTableCell>
                                <StyledTableCell alignItems="center" align="right">
                                    {row.old_value}
                                </StyledTableCell>
                                <StyledTableCell alignItems="center" align="right">
                                    {row.new_value}
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                <DialogActions>
                    <Button className={classes.button} onClick={handleBack} color="default" variant="outlined" disableElevation size="large">
                        <FM id="app.general.back_button" />
                    </Button>
                    <Button disabled={is_empty} className={classes.button} startIcon={<EditIcon />} onClick={handleSurveyEdit} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.survey.edit_survey" />
                    </Button>
                </DialogActions>
            </div>
    
        )
    };
    

    function createLoadingStep() {
        return (
            <div>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}
                        ><CircularProgress color="primary" />
                    </Paper>
                </Grid>
            </Grid>
             <DialogActions>
             <Button className={classes.button} disabled color="primary" variant="contained" disableElevation size="large" >
                 <FM id="app.general.finish_button" />
             </Button>
         </DialogActions>
         </div>
        );
    };

    function createResultStep() {
        return(
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        { success ? 
                        <Alert severity="success">
                            <AlertTitle>{success}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                        { failure ? 
                        <Alert severity="error">
                            <AlertTitle>{failure}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button className={classes.button} onClick={handleFinish} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.general.finish_button" />
                    </Button>
                </DialogActions>
            </div>
        )
    };

    return (
        <div>
        <Paper variant='outlined' className={classes.stepsPaper}>
            <Stepper className={classes.stepper} activeStep={activeStep}>
                { steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                }) }
            </Stepper>
        </Paper>
        {getStepContent(activeStep)}
    </div>
    );
}

SurveyEditForm.propTypes = {
    survey_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    survey_url: PropTypes.string.isRequired,
    start_dt: PropTypes.string.isRequired,
    end_dt: PropTypes.string.isRequired,
    active_status: PropTypes.string.isRequired,
    is_global: PropTypes.bool.isRequired,
    is_published: PropTypes.bool.isRequired,
    priority: PropTypes.number.isRequired,
    coin_award: PropTypes.number.isRequired,
    closeDialog: PropTypes.func.isRequired
}