import React from "react";
import './App.css';

import { AuthenticationScope } from "./components/AuthContext";
import { AWSLambdaScope } from './components/AWSLambda';
import Header from './components/Header';
import Routes from "./components/router/Routes";
import LanguageSelector from './components/LanguageSelector';

export default function App() {
  return (
    <AuthenticationScope>
      <AWSLambdaScope>
        <Header />
        <Routes />
        <div className="footer">
          <LanguageSelector />
        </div>
      </AWSLambdaScope>
    </AuthenticationScope>
  );
};