import React, { useState } from "react";

import { FormattedMessage } from 'react-intl';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import BannerPanel from '../banner/BannerPanel';
import SurveyPanel from '../survey/SurveyPanel';
import TargetedLinkPanel from '../targeted_link/TargetedLinkPanel';
import FeatureStatePanel from '../feature_state/FeatureStatePanel';



export default function AdminPanel() {
    const [activeTab, setActiveTab] = useState('banner');

    const handleChange = (event, newState) => {
        setActiveTab(newState);
    }

    function TabPanel(props) {
        const { children, value, index } = props;
        return (
            <div hidden={value !== index}>
                {value === index && <div>{children}</div>}
            </div>
        );
    }
      
    return (
        <React.Fragment>
            <Paper>
                <Tabs
                    value={activeTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label={<FormattedMessage id = "app.banner.title" />} value="banner"/>
                    <Tab label={<FormattedMessage id = "app.survey.title" />} value="survey"/>
                    <Tab label={<FormattedMessage id = "app.targeted_link.title" />} value="targeted-link"/>
                    <Tab label={<FormattedMessage id = "app.feature_state.title" />} value="feature-state"/>
                </Tabs>
            </Paper>
            <TabPanel value={activeTab} index="banner" >
                <BannerPanel />
            </TabPanel>
            <TabPanel value={activeTab} index="survey" >
                <SurveyPanel />
            </TabPanel>
            <TabPanel value={activeTab} index="targeted-link" >
                <TargetedLinkPanel />
            </TabPanel>
            <TabPanel value={activeTab} index="feature-state" >
                <FeatureStatePanel />
            </TabPanel>
        </React.Fragment>
    );
}
