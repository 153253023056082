import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Custom Components
import { reformatDateString } from '../../../Utils';
import { useBannerContext } from '../../BannerPanel';
import { GlobalStatusChip, StyledTooltip } from 'components/widgets/CustomComponent';

// AWS Imports
import { useAWSLambdaContext } from '../../../AWSLambda';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        height: "100%",
    },
    stepsPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        background: '#e6e6e6',
    },
    button: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}
));

export default function BannerPublishMenu(props) {
    const {
        banner_id,
        title,
        description,
        image_url,
        start_dt,
        end_dt,
        is_global,
        is_published,
        show_before_start,
        show_after_expiry
    } = props;
    const classes = useStyles();

    const { publishBanner, hideBanner } = useAWSLambdaContext();
    const { handleBannerFetch } = useBannerContext();

    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [failure, setFailure] = useState('');
    const [success, setSuccess] = useState('');
    const [response, setResponse] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleOpen = () => {
        handleReset();
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleFinish = () => {
        handleBannerFetch();
        setOpenDialog(false);
    };

    function getSteps() {
        return [is_published ? <FM id="app.banner.hide_banner" /> : <FM id="app.banner.publish_banner" />, <FM id="app.general.result" />];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return createBannerInfoSheet();
            case 1:
                return createLoadingStep();
            case 2:
                return createResultStep();
            default:
                return 'Unknown Step';
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleBannerPublish = async () => {
        setLoading(true);
        handleNext();
        const { success, message, response, error } = await publishBanner(banner_id);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        setLoading(false);
        handleNext();
    }

    const handleBannerHide = async () => {
        setLoading(true);
        handleNext();
        const { success, message, response, error } = await hideBanner(banner_id);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        setLoading(false);
        handleNext();
    }


    function createBannerInfoSheet() {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper variant='outlined' className={classes.paper}>
                        <strong>
                            <FM id="app.banner.attributes.banner_id" />{": "}
                        </strong>
                        {banner_id}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant='outlined' className={classes.paper}>
                        <strong>{title}</strong>
                        <br/>
                        {description}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Paper variant='outlined' className={classes.paper}>
                                <GlobalStatusChip is_global={is_global} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper variant='outlined' className={classes.paper}>
                                <Grid container spacing={1} alignItems="stretch" direction="row" justify="center">
                                    <Grid item xs={4}>
                                        <StyledTooltip title={<FM id="app.banner.show_before_start_status" values={{ status: show_before_start ? "✅" : "❌" }} />}>
                                            <div>
                                                {reformatDateString(start_dt)}
                                            </div>
                                        </StyledTooltip>
                                    </Grid>
                                    <Grid item xs={4}>
                                        → 
                                    </Grid>
                                    <Grid item xs={4}>
                                        <StyledTooltip title={<FM id="app.banner.show_after_expiry_status" values={{ status: show_after_expiry ? "✅" : "❌" }} />}>
                                            <div>
                                                {reformatDateString(end_dt)}
                                            </div>
                                        </StyledTooltip>
                                    </Grid>
                                </Grid>
                            </Paper>                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid style={{ height: "100%" }}>
                        <Paper variant='outlined' className={classes.paper}>
                            <img width="100%" height="auto" src={image_url} alt={banner_id} />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    function createLoadingStep() {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}
                        ><CircularProgress color="primary" />
                    </Paper>
                </Grid>
            </Grid>
        );
    };

    function createResultStep() {
        return(
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    { success ? 
                    <Alert severity="success">
                        <AlertTitle>
                            {success}
                        </AlertTitle>
                        Response Data: {response}
                    </Alert> : <div/> }
                    { failure ? 
                    <Alert severity="error">
                        <AlertTitle>
                            {failure}
                        </AlertTitle>
                        Response Data: {response}
                    </Alert> : <div/> }
                </Grid>
            </Grid>
        )
    };


    return (
        <React.Fragment>
                    { is_published ? 
                    <StyledTooltip title={<FM id="app.general.tooltips.hide" />}>
                        <IconButton color="primary" onClick={handleOpen}>
                            <VisibilityOffIcon />
                        </IconButton>
                    </StyledTooltip> :
                    <StyledTooltip title={<FM id="app.general.tooltips.publish" />}>
                        <IconButton color="primary" onClick={handleOpen}>
                            <PublishIcon />
                        </IconButton>
                    </StyledTooltip> }
                    <Dialog onClose={handleClose} open={openDialog} fullWidth maxWidth="sm">
                        <DialogContent>
                        <Paper variant='outlined' className={classes.stepsPaper}>
                            <Stepper className={classes.stepper} activeStep={activeStep}>
                                { steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                                }) }
                            </Stepper>
                            </Paper>
                            {getStepContent(activeStep)}
                        </DialogContent>
                    <DialogActions>
                        { activeStep === 0 ? 
                        <div>
                            <Button className={classes.button} onClick={handleClose} color="default" variant="outlined" disableElevation size="large">
                                <FM id="app.general.back_button" />
                            </Button>
                            { is_published ?  
                            <Button className={classes.button} onClick={handleBannerHide} color="#e6e6e6" variant="contained" disableElevation size="large" startIcon={<VisibilityOffIcon />}>
                                <FM id="app.banner.hide_banner" />
                            </Button> :
                            <Button className={classes.button} onClick={handleBannerPublish} color="#primary" variant="contained" disableElevation size="large" startIcon={<PublishIcon />}>
                                <FM id="app.banner.publish_banner" />
                            </Button> }
                        </div>
                        :
                        <Button className={classes.button} disabled={loading} onClick={handleFinish} color="primary" variant="contained" disableElevation size="large" >
                            <FM id="app.general.finish_button" />
                        </Button> }

                        

                    </DialogActions>
                    </Dialog>
                </React.Fragment >
    )
}

BannerPublishMenu.propTypes = {
    banner_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    target_url: PropTypes.string.isRequired,
    start_dt: PropTypes.string.isRequired,
    end_dt: PropTypes.string.isRequired,
    active_status: PropTypes.string.isRequired,
    is_global: PropTypes.bool.isRequired,
    is_published: PropTypes.bool.isRequired,
    priority: PropTypes.number.isRequired,
    show_before_start: PropTypes.bool.isRequired,
    show_after_expiry: PropTypes.bool.isRequired
}