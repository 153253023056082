import React, { useState, useContext, createContext } from "react";
import { Auth } from 'aws-amplify';

export const AuthContext = createContext(null);

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthenticationScope(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [credentials_local, setCredentials] = useState({});
  const [username, setUsername] = useState('');
  
  const onPageLoad = () => {
    return Auth.currentAuthenticatedUser()
      .then((result) => {
        setUsername(result.username);
        setIsAuthenticated(true);
      })
      .catch((error) => {
        console.log("Nobody is logged in: " + error + ".");
      })
    }

  const getCognitoAuthentication = (username, password) => {
    return Auth.signIn(username, password)
        .then((result) => {
          return {result};
        })
        .catch((error) => {
          return {error};
        });
  };

  const getCredentials = async () => {
    const { credentials, error } = await Auth.currentCredentials();
    setCredentials(credentials);
  };

  const signIn = async (username, password) => {
    const {result, error} = await getCognitoAuthentication(username, password);
    if (result) {
      const challengeName = result.challengeName;
      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        return {
          challengeName,
          result,
          success: true,
          message: 'New Password Required.',
        };
      };
      setIsAuthenticated(true);
      setUsername(result.username);
      return {
        result,
        success: true,
        message: 'Sign-In Successful.',
      };
    } else {
      return {
        error,
        success: false,
        message: 'Sign-In Failed.',
      };
    };
  };

  const signOut = async () => {
    return await Auth.signOut()
        .then((result) => {
          setIsAuthenticated(false);
          return {
            success: true,
            result
          }
        })
        .catch((error) => {
          return {
            success: false,
            error
          };
        });
  };

  const newPasswordSubmit = async (loginResult, newPassword) => {
   const {result, error} = await completePassword(loginResult, newPassword);
   if (result) {
     setIsAuthenticated(true);
     setUsername(result.username);
     return {
       success: true,
       message: 'New Password has been setup.',
       result

     };
   } else {
     return {
       success: false,
       message: 'New Password failed to setup.',
       error
     };
   }
 };

  const completePassword = (user, password) => {
   return Auth.completeNewPassword(user, password)
       .then((result) => {
         return {result};
       })
       .catch((error) => {
         return {error};
       });
 };

  return(
    <AuthContext.Provider value={{
      isAuthenticated,
      username,
      setIsAuthenticated,
      onPageLoad,
      signIn,
      signOut,
      getCredentials,
      newPasswordSubmit,
    }}>
      {props.children}
    </AuthContext.Provider>
  )
}
