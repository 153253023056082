const config = {
  env: process.env.REACT_APP_ENVIRONMENT_TAG,
    cognito: {
      REGION: process.env.REACT_APP_AWS_REGION,
      USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
    },
  };
  
  export default config;
  