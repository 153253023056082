import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// Form Components
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, Field } from "formik";
import {  MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as yup from "yup";

// Custom Components
import {
    ValidatedTextField,
    StyledTableCell,
    StyledTableRow
} from "components/widgets/CustomComponent";
import { extractDate, isEmpty } from 'components/Utils';
import { useFeatureStateContext } from 'components/feature_state/FeatureStatePanel';

// AWS Imports
import { useAWSLambdaContext } from "components/AWSLambda";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 900,
        height: "100%",
    },
    stepsPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        background: '#e6e6e6',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    div: {
        float: "right",
    }
}
));

export default function FeatureStateEditForm(props) {
    const {
        feature_name,
        is_available,
        closeDialog
    } = props; 
    const classes = useStyles();

    const [formValues, setFormValues] = useState({is_available: is_available});
    const [updates, setUpdates] = useState({});
    const [failure, setFailure] = useState('');
    const [success, setSuccess] = useState('');
    const [response, setResponse] = useState('');
    const [activeStep, setActiveStep] = useState(0);

    const { editFeatureState } = useAWSLambdaContext();
    const { handleFeatureStateFetch } = useFeatureStateContext();

    const handleFeatureStateEdit = async () => {
        handleNext();
        const {success, message, response, error } = await editFeatureState(feature_name, updates);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        handleNext();
    };
    
    const calculateChanges = (data) => {
        var output = {};
        const attributes = ["is_available"];

        for (var index in attributes) {
            if (props[attributes[index]] !== data[attributes[index]]) {
                output[attributes[index]] = data[attributes[index]];
            }
        }

        setUpdates(output);
    }


    const getSteps = () => {
        return [<FM id="app.feature_state.change_feature_state" />, <FM id="app.general.confirm_changes" />, <FM id="app.general.result" />];
    }

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleFinish = () => {
        handleFeatureStateFetch();
        closeDialog();
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return createInputStep();
            case 1:
                return createConfirmStep();
            case 2:
                return createLoadingStep();
            case 3:
                return createResultStep();
            default:
                return 'Unknown Step';
        }
    }

    const validationSchema = yup.object({
        feature_name: yup
            .string(),
        is_available: yup
            .number()
            .integer(<FM id="app.general.integer_error"/>)
            .oneOf([0, 1], <FM id="app.feature_state.is_available_validation"/>)
            .required(<FM id="app.general.required"/>)
    })

    const createInputStep = () => {
        return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={formValues}
                    onSubmit={(data) => {
                        calculateChanges(data);
                        setFormValues(data);
                        handleNext();
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ValidatedTextField label={<FM id="app.feature_state.attributes.feature_name" />} name="feature_name" type="input" disabled defaultValue={feature_name}/>
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedTextField label={<FM id="app.feature_state.attributes.is_available" />} name="is_available" type="number" InputProps={{inputProps: { max: 1, min: 0}}}/>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button className={classes.button} onClick={closeDialog} color="default" variant="outlined" disableElevation size="large">
                                <FM id="app.general.cancel_button" />
                            </Button>
                            <Button className={classes.button} type="submit" color="primary" variant="contained" disableElevation size="large" >
                                <FM id="app.feature_state.confirm_details" />
                            </Button>
                        </DialogActions>
                    </Form>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        );
    }

    
    const createConfirmStep = () => {
        const is_empty = isEmpty(updates);
        const createRow = (attribute_name, old_value, new_value) => {
            return {attribute_name, old_value, new_value};
        }
        
        const rows = [createRow(<FM id="app.feature_state.attributes.feature_name" />, feature_name, feature_name)]

        if ("is_available" in updates) {
            rows.push(createRow(<FM id="app.feature_state.attributes.is_available" />, is_available, updates.is_available));
        };

        return(
            <div>
                { is_empty ?
                <Paper variant='outlined' className={classes.paper}>
                    <Typography>
                        <FM id="app.general.no_changes_detected" />
                    </Typography>
                </Paper> :
                <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><FM id="app.general.attribute_name" /></StyledTableCell>
                                <StyledTableCell align="right"><FM id="app.general.old_value" /></StyledTableCell>
                                <StyledTableCell align="right"><FM id="app.general.new_value" /></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                            <StyledTableRow key={row.attribute_name}>
                                <StyledTableCell component="th" scope="row">
                                    <b>{row.attribute_name}</b>
                                </StyledTableCell>
                                <StyledTableCell alignItems="center" align="right">
                                    {row.old_value}
                                </StyledTableCell>
                                <StyledTableCell alignItems="center" align="right">
                                    {row.new_value}
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>}
                <DialogActions>
                    <Button className={classes.button} onClick={handleBack} color="default" variant="outlined" disableElevation size="large">
                        <FM id="app.general.back_button" />
                    </Button>
                    <Button disabled={is_empty} className={classes.button} onClick={handleFeatureStateEdit} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.feature_state.change_feature_state" />
                    </Button>
                </DialogActions>
            </div>
    
        )
    };
    

    function createLoadingStep() {
        return (
            <div>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}
                        ><CircularProgress color="primary" />
                    </Paper>
                </Grid>
            </Grid>
             <DialogActions>
             <Button className={classes.button} disabled color="primary" variant="contained" disableElevation size="large" >
                 <FM id="app.general.finish_button" />
             </Button>
         </DialogActions>
         </div>
        );
    };

    function createResultStep() {
        return(
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        { success ? 
                        <Alert severity="success">
                            <AlertTitle>{success}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                        { failure ? 
                        <Alert severity="error">
                            <AlertTitle>{failure}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button className={classes.button} onClick={handleFinish} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.general.finish_button" />
                    </Button>
                </DialogActions>
            </div>
        )
    };

    return (
        <div>
        <Paper variant='outlined' className={classes.stepsPaper}>
            <Stepper className={classes.stepper} activeStep={activeStep}>
                { steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                }) }
            </Stepper>
        </Paper>
        {getStepContent(activeStep)}
    </div>
    );
}

FeatureStateEditForm.propTypes = {
    feature_name: PropTypes.string.isRequired,
    is_available: PropTypes.number.isRequired,
    closeDialog: PropTypes.func.isRequired
}