import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import LanguageWrapper from './components/LanguageWrapper';
import config from './config';

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    identityPoolRegion: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    mandatorySignIn: true,
  }
});


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <LanguageWrapper>
        <App />
      </LanguageWrapper>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
