import React, { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

// Material UI Icons
import AddIcon from '@material-ui/icons/Add';

// Custom Components
import FeatureStateAddForm from './FeatureStateAddForm';


export default function FeatureStateAddMenu() {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
                <FM id="app.feature_state.add_feature_state" />
            </Button>
            <Dialog onClose={handleClose} open={openDialog} disableBackdropClick fullWidth maxWidth="sm">
                <DialogContent>
                    <FeatureStateAddForm closeDialog={handleClose}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}