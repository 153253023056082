import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';

// Material UI Icons
import EditIcon from '@material-ui/icons/Edit';

// Custom Components
import BannerEditForm from './BannerEditForm';
import { StyledTooltip } from 'components/widgets/CustomComponent';


export default function BannerEditMenu(props) {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <React.Fragment>
            <StyledTooltip title={<FM id="app.general.tooltips.edit" />}>
                <IconButton color="primary" onClick={handleClickOpen}>
                    <EditIcon />
                </IconButton>
            </StyledTooltip>
            <Dialog onClose={handleClose} open={openDialog} disableBackdropClick fullWidth maxWidth="md">
                <DialogContent>
                    <BannerEditForm closeDialog={handleClose} {...props}/>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

BannerEditMenu.propTypes = {
    banner_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    target_url: PropTypes.string.isRequired,
    start_dt: PropTypes.string.isRequired,
    end_dt: PropTypes.string.isRequired,
    active_status: PropTypes.string.isRequired,
    is_global: PropTypes.bool.isRequired,
    is_published: PropTypes.bool.isRequired,
    priority: PropTypes.number.isRequired,
    show_before_start: PropTypes.bool.isRequired,
    show_after_expiry: PropTypes.bool.isRequired
}