import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import ArchiveIcon from '@material-ui/icons/Archive';

// Custom Components
import { reformatDateString } from '../../../Utils';
import { useBannerContext } from '../../BannerPanel';
import { 
    GlobalStatusChip,
    StyledTooltip
} from "components/widgets/CustomComponent";
import config from 'config';

// AWS Imports
import { useAWSLambdaContext } from '../../../AWSLambda';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 900,
        height: "100%",
    },
    stepsPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        background: '#e6e6e6',
    },
    button: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}
));

export default function BannerArchiveMenu(props) {
    const {
        banner_id,
        title,
        description,
        image_url,
        start_dt,
        end_dt,
        is_global,
        is_published,
        show_before_start,
        show_after_expiry
    } = props;
    const classes = useStyles();

    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [failure, setFailure] = useState('');
    const [response, setResponse] = useState('');

    const { archiveBanner } = useAWSLambdaContext();
    const { handleBannerFetch } = useBannerContext();

    const handleBannerArchive = async () => {
        setLoading(true);
        handleNext();
        let bucket_name;
        if (config.env === "dev") {
            bucket_name = "walkcoin-advert-banner-dev"
        } else if (config.env === "prod") {
            bucket_name = "walkcoin-advert-banner"
        }
        const { success, message, response, error } = await archiveBanner(banner_id, bucket_name);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        setLoading(false);
        handleNext()
    }

    const handleClickOpen = () => {
        handleReset();
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };



    function getSteps() {
        return [<FM id="app.banner.archive_banner" />, <FM id="app.general.result" />];
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return createBannerInfoSheet();
            case 1:
                return createLoadingStep();
            case 2:
                return createResultStep();
            default:
                return 'Unknown Step';
        }
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    
    const handleReset = () => {
        setActiveStep(0);
    };

    const handleFinish = () => {
        handleBannerFetch();
        setOpenDialog(false);
    };


    function createBannerInfoSheet() {
        return (
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper variant='outlined' className={classes.paper}>
                        <strong>
                            <FM id="app.banner.attributes.banner_id" />{": "}
                        </strong>
                        {banner_id}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper variant='outlined' className={classes.paper}>
                        <strong>{title}</strong>
                        <br/>
                        {description}
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Paper variant='outlined' className={classes.paper}>
                                <GlobalStatusChip is_global={is_global} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper variant='outlined' className={classes.paper}>
                                <Grid container spacing={1} alignItems="stretch" direction="row" justify="center">
                                    <Grid item xs={4}>
                                        <StyledTooltip title={<FM id="app.banner.show_before_start_status" values={{ status: show_before_start ? "✅" : "❌" }} />}>
                                            <div>
                                                {reformatDateString(start_dt)}
                                            </div>
                                        </StyledTooltip>
                                    </Grid>
                                    <Grid item xs={4}>
                                        → 
                                    </Grid>
                                    <Grid item xs={4}>
                                        <StyledTooltip title={<FM id="app.banner.show_after_expiry_status" values={{ status: show_after_expiry ? "✅" : "❌" }} />}>
                                            <div>
                                                {reformatDateString(end_dt)}
                                            </div>
                                        </StyledTooltip>
                                    </Grid>
                                </Grid>
                            </Paper>                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid style={{ height: "100%" }}>
                        <Paper variant='outlined' className={classes.paper}>
                            <img width="100%" height="auto" src={image_url} alt={banner_id} />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        <FM id="app.banner.archive_warning" />
                    </Alert>
                </Grid>
            </Grid>
        )
    }

    function createLoadingStep() {
        return(
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}>
                        <CircularProgress color="primary" />
                    </Paper>
                </Grid>
            </Grid>
        )}

    function createResultStep() {
        return(
        <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="stretch"
    >
        <Grid item xs={12}>
        { success ? <Alert severity="success">
            <AlertTitle>{success}</AlertTitle>
                Response Data: {response}
            </Alert> : <div/>}
        { failure ? <Alert severity="error">
        <AlertTitle>{failure}</AlertTitle>
        Response Data: {response}
        </Alert> : <div/>}
        
        </Grid>
    
    </Grid>
        )}

    return (
        <React.Fragment>
            {
                is_published ?
                <StyledTooltip title={<FM id="app.banner.tooltips.cant_archive" />}>
                    <span>
                        <IconButton color="primary" disabled>
                            <ArchiveIcon />
                        </IconButton>
                        </span>
                </StyledTooltip>
                :
                <React.Fragment>
                    <StyledTooltip title={<FM id="app.general.tooltips.archive" />}>
                        <IconButton color="primary" onClick={handleClickOpen}>
                            <ArchiveIcon />
                        </IconButton>
                    </StyledTooltip>
                    <Dialog onClose={handleClose} open={openDialog} fullWidth maxWidth="sm">
                        <DialogContent>
                        <Paper variant='outlined' className={classes.stepsPaper}>
                            <Stepper className={classes.stepper} activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                            </Paper>
                            {getStepContent(activeStep)}
                        </DialogContent>
                    <DialogActions>
                        { 
                            activeStep === 0 ? 
                            <div>
                                <Button className={classes.button} onClick={handleClose} color="default" variant="outlined" disableElevation size="large">
                                <FM id="app.general.back_button" />
                            </Button>
                            <Button className={classes.button} onClick={handleBannerArchive} color="secondary" variant="contained" disableElevation size="large" startIcon={<ArchiveIcon />}>
                                <FM id="app.banner.archive_banner" />
                            </Button>
                            </div>
                            :
                            <Button className={classes.button} disabled={loading} onClick={handleFinish} color="primary" variant="contained" disableElevation size="large" >
                                <FM id="app.general.finish_button" />
                            </Button>
                        }
                    </DialogActions>
                    </Dialog>
                </React.Fragment >
            }
        </React.Fragment>
    )
}