import React, { useState, useContext } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { LanguageContext } from "./LanguageWrapper";
import { makeStyles } from '@material-ui/core/styles';

import CountryCodeEmoji from "country-code-emoji"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function LanguageSelector() {
    const context = useContext(LanguageContext);
    const classes = useStyles();
    const [activeLanguage, setActiveLanguage] = useState(navigator.language.substring(0,2));

    const handleChange = (event, newLanguage) => {
        setActiveLanguage(newLanguage);
        context.selectLanguage(newLanguage);
    }

    return (
        <div className={classes.root}>
            <ToggleButtonGroup
                color="primary"
                value={activeLanguage}
                onChange={handleChange}
                exclusive
                size="small"
            >
                <ToggleButton value="en">{CountryCodeEmoji("GB")} EN</ToggleButton>
                <ToggleButton value="jp">{CountryCodeEmoji("JP")} JP</ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
}
