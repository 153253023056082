import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom Components
import FeatureStateTable from './FeatureStateTable';
import FeatureStateAddMenu from './menus/feature_state_add_menu/FeatureStateAddMenu';
import { SuccessSnackbar, ErrorSnackbar } from '../widgets/ResponseSnackbar';

// AWS Imports
import { useAWSLambdaContext } from '../AWSLambda';

export const FeatureStateContext = createContext(null);

export function useFeatureStateContext() {
    return useContext(FeatureStateContext);
  }

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.primary,
        fontWeight: 900,
        variant: 'outlined',
        height: "100%",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer,
        color: '#fff',
      },
}));


export default function FeatureStatePanel() {
    const classes = useStyles();

    const { fetchFeatureState } = useAWSLambdaContext();

    const [loading, setLoading] = useState(false);
    const [failure, setFailure] = useState('');
    const [success, setSuccess] = useState('');
    const [activeFeatures, setActiveFeatures] = useState([]);

    const handleFeatureStateFetch = useCallback(async () => {
        setLoading(true);
        const { success, message, response, error } = await fetchFeatureState();
        if (success) {
            setSuccess(message);
            setActiveFeatures(response); 
        } else {
            setFailure(message + error);
        };
        setLoading(false);
      }, [fetchFeatureState]);

    useEffect(() => {
        handleFeatureStateFetch()
    }, [handleFeatureStateFetch]);


    return (
        <FeatureStateContext.Provider value={{ handleFeatureStateFetch }}>
            <div className={classes.root}>
                <CssBaseline />
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Paper className={classes.paper} style={{backgroundColor: '#deffd8'}}>
                                <FM id="app.feature_state.active_section" />
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper position="static" color="default" style={{ textAlign: 'center', height: '100%' }}>
                                <IconButton variant="outlined" onClick={handleFeatureStateFetch} color="primary">
                                    <RefreshIcon />
                                </IconButton>
                                <FeatureStateAddMenu />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <FeatureStateTable rows={activeFeatures} />
                        </Grid>
                    </Grid>
                </Container>

                <ErrorSnackbar message={failure} onClose={setFailure} />
                <SuccessSnackbar message={success} onClose={setSuccess} />

            </div>
        </FeatureStateContext.Provider>
    );
}