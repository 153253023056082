import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export function ErrorSnackbar({message, onClose}) {
  const [open, setOpen] = useState(message.length !== 0);

  const handleClose = (event) => {
    setOpen(false);
    if (onClose) {
      onClose('');
    };
  };

  useEffect( () => {
    if (message.length !== 0) {
      setOpen(true);
    }
  }, [message]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={handleClose} severity="error">
        {message}
      </Alert>
    </Snackbar>
  );
};

ErrorSnackbar.propTypes = {
  message: PropTypes.string, 
  onClose: PropTypes.func,
};

export function SuccessSnackbar({message, onClose}) {
  const [open, setOpen] = useState(message.length !== 0);

  const handleClose = (event) => {
    setOpen(false);

    if (onClose) {
      onClose('');
    };
  };

  useEffect( () => {
    if (message.length !== 0) {
      setOpen(true);
    }
  }, [message]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Alert onClose={handleClose} severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
};

SuccessSnackbar.propTypes = {
  message: PropTypes.string, 
  onClose: PropTypes.func,
};
