import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import AddIcon from '@material-ui/icons/Add';

// Form Components
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from 'formik-material-ui-pickers';
import { Formik, Form, Field } from "formik";
import moment from 'moment';
import {  MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as yup from "yup";

// Custom Components
import {
    ValidatedTextField,
    ValidatedBinarySelectField,
    StyledTableCell,
    StyledTableRow,
} from "components/widgets/CustomComponent";
import { createImageButton } from "components/widgets/ImageButton";
import { createURLButton } from "components/widgets/URLButton";
import { reformatDateString } from 'components/Utils';
import { useSurveyContext } from 'components/survey/SurveyPanel';

// AWS Imports
import { useAWSLambdaContext } from "components/AWSLambda";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 900,
        height: "100%",
    },
    stepsPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        background: '#e6e6e6',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    div: {
        float: "right",
    }
}
));


export default function SurveyAddForm(props) {
    const {
        closeDialog
    } = props; 
    const classes = useStyles();

    const [success, setSuccess] = useState('');
    const [response, setResponse] = useState('');
    const [failure, setFailure] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        survey_id: "",
        title: "",
        global_flag: "",
        start_dt: moment().startOf("day"),
        end_dt: moment().endOf("day"),
        published_flag: false,
        priority: null,
        coin_award: null,
        survey_url: ""
    });

    const { addSurvey } = useAWSLambdaContext();
    const { handleSurveyFetch } = useSurveyContext();

    const handleSurveyAdd = async () => {
        handleNext();
        formData.start_dt = moment(formData.start_dt).format("YYYY/MM/DD[T]HH:mm");
        formData.end_dt = moment(formData.end_dt).format("YYYY/MM/DD[T]HH:mm");
        const {success, message, response, error } = await addSurvey(formData);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        handleNext();
    };

    const getSteps = () => {
        return [<FM id="app.survey.add_survey" />, <FM id="app.general.confirm_details" />, <FM id="app.general.result" />];
    };

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = () => {
        handleSurveyFetch();
        closeDialog();
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return createInputStep();
            case 1:
                return createConfirmStep();
            case 2:
                return createLoadingStep();
            case 3:
                return createResultStep();
            default:
                return 'Unknown Step';
        }
    };

    const validationSchema = yup.object({
        survey_id: yup
            .string()
            .required(<FM id="app.general.required"/>),
        title: yup
            .string()
            .required(<FM id="app.general.required"/>),
        description: yup
            .string()
            .required(<FM id="app.general.required"/>),
        global_flag: yup 
            .boolean()
            .required(<FM id="app.general.required"/>),
        published_flag: yup
            .boolean()
            .required(<FM id="app.general.required"/>),
        priority: yup
            .number()
            .typeError(<FM id="app.general.integer_error"/>)
            .required(<FM id="app.general.required"/>),
        coin_award: yup
            .number()
            .typeError(<FM id="app.general.integer_error"/>)
            .required(<FM id="app.general.required"/>),
        start_dt: yup
            .string()
            .required(<FM id="app.general.required"/>)
            .nullable(),
        end_dt: yup
            .string()
            .required(<FM id="app.general.required"/>)
            .nullable(),
        survey_url: yup
            .string()
            .required(<FM id="app.general.required"/>)
            .url(<FM id="app.general.url_error"/>)
    })

    const createInputStep = () => {
        return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={formData}
                    onSubmit={(data) => {
                        setFormData(data);
                        console.log(data);
                        handleNext();
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_id" />} name="survey_id" type="input" />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_title" />} name="title" type="input" />
                            </Grid>
                            <Grid item xs={12}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_description" />} name="description" type="input" multiline rows={2} />
                            </Grid>
                            <Grid item xs={4}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.priority" />} name="priority" type="number"/>
                            </Grid>
                            <Grid item xs={4}>
                                <ValidatedBinarySelectField 
                                    label={<FM id="app.survey.attributes.survey_scope" />} 
                                    name="global_flag" 
                                    true_state={<FM id="app.general.tooltips.global" />} 
                                    false_state={<FM id="app.general.tooltips.targeted" />} 
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ValidatedBinarySelectField 
                                    label={<FM id="app.survey.attributes.publish_state" />} 
                                    name="published_flag" 
                                    true_state={<FM id="app.general.tooltips.published" />} 
                                    false_state={<FM id="app.general.tooltips.hidden" />} 
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field 
                                    component={DateTimePicker}
                                    fullWidth
                                    autoOk
                                    disableToolbar 
                                    label={<FM id="app.survey.attributes.start_dt" />}
                                    name="start_dt" 
                                    margin="dense"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd - HH:mm"
                                    ampm={false}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field 
                                    fullWidth
                                    component={DateTimePicker}
                                    disableToolbar
                                    autoOk
                                    defaultValue={null}
                                    label={<FM id="app.survey.attributes.end_dt" />}
                                    name="end_dt" 
                                    margin="dense"
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="yyyy/MM/dd - HH:mm"
                                    ampm={false}
                                    minDate={values.start_dt}
                                    minDateMessage="End Date cannot be before Start Date."
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.survey_url" />} name="survey_url" type="input" />
                            </Grid>
                            <Grid item xs={4}>
                                <ValidatedTextField label={<FM id="app.survey.attributes.coin_award" />} name="coin_award" type="number" />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button className={classes.button} onClick={closeDialog} color="default" variant="outlined" disableElevation size="large">
                                <FM id="app.general.cancel_button" />
                            </Button>
                            <Button className={classes.button} type="submit" color="primary" variant="contained" disableElevation size="large" >
                                <FM id="app.general.verify_button" />
                            </Button>
                        </DialogActions>
                    </Form>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        );
    }

    const createRow = (attribute_name, value) => {
        return {attribute_name, value};
    }

    const rows = [
        createRow(<FM id="app.survey.attributes.survey_id" />, formData.survey_id),
        createRow(<FM id="app.survey.attributes.survey_title" />, formData.title),
        createRow(<FM id="app.survey.attributes.survey_description" />, formData.description),
        createRow(<FM id="app.survey.attributes.priority" />, formData.priority),
        createRow(<FM id="app.survey.attributes.banner_scope" />, formData.global_flag ? <FM id="app.general.tooltips.global" /> : <FM id="app.general.tooltips.targeted" />),
        createRow(<FM id="app.survey.attributes.publish_state" />, formData.published_flag ? <FM id="app.general.tooltips.published" /> : <FM id="app.general.tooltips.hidden" />),
        createRow(<FM id="app.survey.attributes.start_dt" />, reformatDateString(formData.start_dt)),
        createRow(<FM id="app.survey.attributes.end_dt" />, reformatDateString(formData.end_dt)),
        createRow(<FM id="app.survey.attributes.survey_url" />, createURLButton(formData.survey_url)),
        createRow(<FM id="app.survey.attributes.coin_award" />, formData.coin_award)
    ];

    const createConfirmStep = () => {
        return(
            <div>
                <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><FM id="app.general.attribute_name" /></StyledTableCell>
                                <StyledTableCell align="right"><FM id="app.general.value" /></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                            <StyledTableRow key={row.attribute_name}>
                                <StyledTableCell component="th" scope="row">
                                    <b>{row.attribute_name}</b>
                                </StyledTableCell>
                                <StyledTableCell alignItems="center" align="right">
                                    {row.value}
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <Button className={classes.button} onClick={handleBack} color="default" variant="outlined" disableElevation size="large">
                        <FM id="app.general.back_button" />
                    </Button>
                    <Button className={classes.button} startIcon={<AddIcon />} onClick={handleSurveyAdd} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.survey.add_survey" />
                    </Button>
                </DialogActions>
            </div>
    
        )
    };

    const createLoadingStep = () => {
        return (
            <div>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}
                        ><CircularProgress color="primary" />
                    </Paper>
                </Grid>
            
            </Grid>
             <DialogActions>
             <Button disabled className={classes.button} color="primary" variant="contained" disableElevation size="large" >
                 <FM id="app.general.finish_button" />
             </Button>
         </DialogActions>
         </div>
        );
    };

    const createResultStep = () => {
        return(
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        { success ? 
                        <Alert severity="success">
                            <AlertTitle>{success}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                        { failure ? 
                        <Alert severity="error">
                            <AlertTitle>{failure}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                    </Grid>
                    {success ?
                        <Grid item xs={12}>
                        <Alert severity="warning">
                            <FM id="app.survey.add_survey_warning" />
                        </Alert>
                    </Grid> : <div/> }
                </Grid>
                <DialogActions>
                    <Button className={classes.button} onClick={handleFinish} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.general.finish_button" />
                    </Button>
                </DialogActions>
            </div>
        )
    };

    return (
        <div>
            <Paper variant='outlined' className={classes.stepsPaper}>
                <Stepper className={classes.stepper} activeStep={activeStep}>
                    { steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    }) }
                </Stepper>
            </Paper>
            {getStepContent(activeStep)}
        </div>
    );
}

SurveyAddForm.propTypes = {
    closeDialog: PropTypes.func.isRequired
}