import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import FeatureStateTableRow from './FeatureStateTableRow';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));


export default function FeatureStateTable(props) {
    const {
        rows
    } = props;
    const classes = useStyles();

    function createFeatureStateTableRow(object) {
        return <FeatureStateTableRow
            key={object.feature_name}
            feature_name={object.feature_name}
            is_available={object.is_available}
        />
    }

    return(
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
                <TableHead  style={{backgroundColor: '#d8ecff'}}>
                    <TableRow>
                        <TableCell><FM id = "app.feature_state.attributes.feature_name" /></TableCell>
                        <TableCell align="left"><FM id = "app.feature_state.attributes.is_available" /></TableCell>
                        <TableCell align="center"><FM id = "app.feature_state.edit_icon" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(createFeatureStateTableRow)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

FeatureStateTable.propTypes = {
    rows: PropTypes.array.isRequired
}