import React, { createContext, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Lambda } from "@aws-sdk/client-lambda";

export const AWSLambdaContext = createContext(null);

export function useAWSLambdaContext() {
    return useContext(AWSLambdaContext);
  }

export function AWSLambdaScope(props) {
    const awsRegion = process.env.REACT_APP_AWS_REGION;
    const awsAccountId = process.env.REACT_APP_AWS_ACCOUNT_ID;

    const textDecoder = new TextDecoder("utf-8");

    const add_banner_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:add_banner`;
    const archive_banner_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:archive_banner`;
    const edit_banner_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:edit_banner`;
    const fetch_banners_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:fetch_banners`;
    const hide_banner_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:hide_banner`;
    const publish_banner_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:publish_banner`;

    const add_survey_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:add_survey`;
    const archive_survey_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:archive_survey`;
    const edit_survey_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:edit_survey`;
    const fetch_surveys_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:fetch_surveys`;
    const hide_survey_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:hide_survey`;
    const publish_survey_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:publish_survey`;

    const fetch_feature_state_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:fetch_feature_state`;
    const add_feature_state_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:add_feature_state`;
    const edit_feature_state_function = `arn:aws:lambda:${awsRegion}:${awsAccountId}:function:edit_feature_state`;

    const runLambdaFunction = async (function_name, payload) => {
        const credentials = await Auth.currentUserCredentials();
        const lambda = new Lambda({
            credentials: Auth.essentialCredentials(credentials),
            region: awsRegion
        });
        return lambda.invoke({
            FunctionName: function_name,
            Payload: JSON.stringify(payload),
        });
    }

// **********************************************
// *** LAMBDA FUNCTIONS FOR THE BANNER MODULE ***
// **********************************************

    const addBanner = async (payload) => {
        return await runLambdaFunction(add_banner_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Banner Add Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Banner Add Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Banner Add Failed",
                    error
                }
            });
    };

    const archiveBanner = async (banner_id, bucket_name) => {
        const payload = { banner_id: banner_id, bucket_name: bucket_name };
        return await runLambdaFunction(archive_banner_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Banner Archive Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Banner Archive Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Banner Archive Failed",
                    error
                }
            });
    };

    const editBanner = async (banner_id, updates) => {
        const payload = { banner_id: banner_id, updates: updates };
        return await runLambdaFunction(edit_banner_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Banner Edit Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Banner Edit Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Banner Edit Failed",
                    error
                }
            });
    };

    const fetchBanners = async () => {
        const payload = {};
        return await runLambdaFunction(fetch_banners_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Banner Fetch Successful",
                        response: JSON.parse(result)
                    }
                } else {
                    return {
                        success: false,
                        message: "Banner Fetch Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Banner Fetch Failed",
                    error
                }
            });
    };

    const hideBanner = async (banner_id) => {
        const payload = { banner_id: banner_id };
        return await runLambdaFunction(hide_banner_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Banner Hide Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Banner Hide Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Banner Hide Failed",
                    error
                }
            });
    };

    const publishBanner = async (banner_id) => {
        const payload = { banner_id: banner_id };
        return await runLambdaFunction(publish_banner_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Banner Publish Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Banner Publish Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                return {
                    success: false,
                    message: "Banner Publish Failed",
                    error
                }
            });
    };

// **********************************************
// *** LAMBDA FUNCTIONS FOR THE SURVEY MODULE ***
// **********************************************

    const addSurvey = async (payload) => {
        return await runLambdaFunction(add_survey_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Survey Add Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Survey Add Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Survey Add Failed",
                    error
                }
            });
    };

    const archiveSurvey = async (survey_id, bucket_name) => {
        const payload = { survey_id: survey_id, bucket_name: bucket_name };
        return await runLambdaFunction(archive_survey_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Survey Archive Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Survey Archive Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Survey Archive Failed",
                    error
                }
            });
    };

    const editSurvey = async (survey_id, updates) => {
        const payload = { survey_id: survey_id, updates: updates };
        return await runLambdaFunction(edit_survey_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Survey Edit Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Survey Edit Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Survey Edit Failed",
                    error
                }
            });
    };


    const fetchSurveys = async () => {
        const payload = {};
        return await runLambdaFunction(fetch_surveys_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: "SUCCESS",
                        message: "Survey Fetch Successful",
                        response: JSON.parse(result)
                    }
                } else {
                    return {
                        success: false,
                        message: "Survey Fetch Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                return {
                    success: false,
                    message: "Survey Fetch Failed",
                    error
                }
            });
    }

    const hideSurvey = async (survey_id) => {
        const payload = { survey_id: survey_id };
        return await runLambdaFunction(hide_survey_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Survey Hide Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Survey Hide Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Survey Hide Failed",
                    error
                }
            });
    };

    const publishSurvey = async (survey_id) => {
        const payload = { survey_id: survey_id };
        return await runLambdaFunction(publish_survey_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Survey Publish Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Survey Publish Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                return {
                    success: false,
                    message: "Survey Publish Failed",
                    error
                }
            });
    };

// **********************************************
// *** LAMBDA FUNCTIONS FOR FEATURE STATE MODULE ***
// **********************************************

    const addFeatureState = async (payload) => {
        return await runLambdaFunction(add_feature_state_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Feature State Add Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Feature State Add Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Feature State Add Failed",
                    error
                }
            });
    };

    const editFeatureState = async (feature_name, updates) => {
        const payload = { feature_name: feature_name, updates: updates };
        return await runLambdaFunction(edit_feature_state_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Feature State Edit Successful",
                        response: result
                    }
                } else {
                    return {
                        success: false,
                        message: "Feature State Edit Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Feature State Edit Failed",
                    error
                }
            });
    };

    const fetchFeatureState = async () => {
        const payload = {};
        return await runLambdaFunction(fetch_feature_state_function, payload)
            .then((data) => {
                const result = textDecoder.decode(data.Payload);
                if (data["FunctionError"] === undefined) {
                    return {
                        success: true,
                        message: "Feature State Fetch Successful",
                        response: JSON.parse(result)
                    }
                } else {
                    return {
                        success: false,
                        message: "Feature State Fetch Failed",
                        error: result
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                return {
                    success: false,
                    message: "Feature State Fetch Failed",
                    error
                }
            });
    };


    return(
        <AWSLambdaContext.Provider value={{
            addBanner,
            archiveBanner,
            editBanner,
            fetchBanners,
            hideBanner,
            publishBanner,
            addSurvey,
            archiveSurvey,
            editSurvey,
            fetchSurveys,
            hideSurvey,
            publishSurvey,
            fetchFeatureState,
            addFeatureState,
            editFeatureState
        }}>
            {props.children}
        </AWSLambdaContext.Provider>
    )
}
