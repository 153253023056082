
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Popover from '@material-ui/core/Popover';
import Backdrop from '@material-ui/core/Backdrop';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage as FM } from 'react-intl';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { StyledTooltip } from 'components/widgets/CustomComponent';

const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }));


export function createURLButton(url){
return(
    <URLButton url={url} />
)
}


export default function URLButton(props) {




    const classes = useStyles();
    const url = props.url
    const [anchorEl, setAnchorEl] = useState(null);


    const handleCopy = () => {
        navigator.clipboard.writeText(props.url)
    }

    if (url === undefined || url === "") {
        return(<StyledTooltip title={<FM id="app.banner.tooltips.no_link" />}>
            <span>
                <IconButton color="primary" disabled>
                    <LinkOffIcon />
                </IconButton>
            </span>
        </StyledTooltip>
        )
    } else {

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
      
        const handleClose = () => {
          setAnchorEl(null);
        };
      
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return(
        <div>
        <IconButton color="primary" onClick={handleClick}>
            <LinkIcon />
        </IconButton>
        <Backdrop className={classes.backdrop} open={open}>
        
            <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            class={{}}
            onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item>
                <Typography className={classes.typography}>{url}</Typography>
                </Grid>
                <Grid item>
                    <StyledTooltip title={<FM id="app.general.copy" />}>
                <IconButton color="primary" onClick={handleCopy}>


            <FileCopyIcon />
        </IconButton>
        </StyledTooltip>
        </Grid>
        <Grid item>
                <IconButton color="primary" onClick={handleClose}>


            <CloseIcon />
        </IconButton>
        </Grid>
        </Grid>

                    </Popover>
      </Backdrop>
            </div>)


    }
}
