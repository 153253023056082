import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom Components
import BannerTable from './BannerTable';
import BannerAddMenu from './menus/banner_add_menu/BannerAddMenu';
import { SuccessSnackbar, ErrorSnackbar } from '../widgets/ResponseSnackbar';

// AWS Imports
import { useAWSLambdaContext } from '../AWSLambda';

export const BannerContext = createContext(null);

export function useBannerContext() {
    return useContext(BannerContext);
  }

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.primary,
        fontWeight: 900,
        variant: 'outlined',
        height: "100%",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer,
        color: '#fff',
      },
}));


export default function BannerPanel() {
    const classes = useStyles();

    const { fetchBanners } = useAWSLambdaContext();

    const [loading, setLoading] = useState(false);
    const [failure, setFailure] = useState('');
    const [success, setSuccess] = useState('');

    const [activeBanners, setActiveBanners] = useState([]);
    const [upcomingBanners, setUpcomingBanners] = useState([]);
    const [expiredBanners, setExpiredBanners] = useState([]);
    const [inactiveBanners, setInactiveBanners] = useState([]);

    const handleBannerFetch = useCallback(async () => {
        setLoading(true);
        const { success, message, response, error } = await fetchBanners();    
        if (success) {
            setSuccess(message);
            setActiveBanners(response.active); 
            setUpcomingBanners(response.upcoming);
            setExpiredBanners(response.expired);
            setInactiveBanners(response.inactive);
        } else {
            setFailure(message + error);
        };
        setLoading(false);
      }, [fetchBanners]);

    useEffect(() => {
        handleBannerFetch()
    }, [handleBannerFetch]);

    return (
        <BannerContext.Provider value={{ handleBannerFetch }}>
            <div className={classes.root}>
                <CssBaseline />
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Container maxWidth="lg">
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <Paper className={classes.paper} style={{backgroundColor: '#deffd8'}}>
                                <FM id="app.banner.active_section" />
                            </Paper>
                        </Grid>
                        <Grid item xs={3}>
                            <Paper position="static" color="default" style={{ textAlign: 'center', height: '100%' }}>
                                <IconButton variant="outlined" onClick={handleBannerFetch} color="primary">
                                    <RefreshIcon />
                                </IconButton>
                                <BannerAddMenu />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <BannerTable rows={activeBanners} />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper} style={{backgroundColor: '#d8ecff'}}>
                                <FM id="app.banner.upcoming_section" />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <BannerTable rows={upcomingBanners} />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper} style={{backgroundColor: '#ffd8d8'}}>
                                <FM id="app.banner.expired_section" />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <BannerTable rows={expiredBanners} />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.paper} style={{backgroundColor: '#e5e5e5'}}>
                                <FM id="app.banner.inactive_section" />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <BannerTable rows={inactiveBanners} />
                        </Grid>
                    </Grid>
                </Container>

                <ErrorSnackbar message={failure} onClose={setFailure} />
                <SuccessSnackbar message={success} onClose={setSuccess} />

            </div>
        </BannerContext.Provider>
    );
}