import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Container, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Alert from '@material-ui/lab/Alert';
import { FormattedMessage as FM } from 'react-intl';

import { useAuthContext } from "../AuthContext";

import DialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 900,
        height: "100%",
    },
    button: {
        marginTop: theme.spacing(1),
    }
}
));

const CustomTextField = ({ label, disabled, type, multiline, rows, ...props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            type={type}
            disabled={disabled}
            multiline={multiline}
            rows={rows}
            margin="dense"
            label={label}
            {...props}
        />
    )
}

export default function SignInPanel() {
    const classes = useStyles();

    const history = useHistory();

    const { isAuthenticated, signIn, newPasswordSubmit } = useAuthContext();


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginResponse, setLoginResponse] = useState({});
    const [newPassword, setNewPassword] = useState('');

    const [signInLoading, setSignInLoading] = useState(false);
    const [signInFailure, setSignInFailure] = useState('');

    const [newPasswordLoading, setNewPasswordLoading] = useState(false);
    const [newPasswordFailure, setNewPasswordFailure] = useState('');

    const [openDialog, setOpenDialog] = useState(false);

    const handleOpen = () => {
        setOpenDialog(true);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
      }, [isAuthenticated, history]);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (username === "" || password === "") {
            setSignInFailure(<FM id="app.signin.empty_field_error" />);
            return;
        }
        setSignInFailure("");
        setSignInLoading(true);
        const { 
            success, 
            challengeName, 
            result, 
            error
        } = await signIn(username, password);
        if (success) {
            if (challengeName === "NEW_PASSWORD_REQUIRED") {
                setLoginResponse(result);
                handleOpen();
            }
            else {
                history.push("/");
            }
        } else {
            if (error.message === "Incorrect username or password."){
                setSignInFailure(<FM id="app.signin.incorrect_credentials" />);
            } else {
                setSignInFailure(error.message);
            }
        }
        setSignInLoading(false);
    };

    const handleNewPassword = async () => {
        setNewPasswordFailure("");
        setNewPasswordLoading(true);
        const { success, result, error } = await newPasswordSubmit(loginResponse, newPassword)
        if (success) {
            handleClose();
            console.log(result);
            history.push("/");

        } else {
            console.error('Error', error);
            setNewPasswordFailure(error.message);
        };
        setNewPasswordLoading(false);
    };

    return (
        <React.Fragment>
            <Container maxWidth="xs">
                <Paper variant="outlined" className={classes.paper}>
                    <form autoComplete="off" onSubmit={handleLogin}>
                        <Grid container spacing={2}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <CustomTextField onChange={event => setUsername(event.target.value)} label={<FM id="app.signin.username" />} type="input" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextField onChange={event => setPassword(event.target.value)} label={<FM id="app.signin.password" />} type="password" />
                                </Grid>
                                <Grid item xs={12}>

                                { !!signInFailure ? 
                                    <Alert severity="error">
                                        {signInFailure}
                                    </Alert> : <div/> }
                                </Grid>
                                <Grid item xs={12}>
                                    <Button disabled={signInLoading} className={classes.button} color="primary" variant="contained" disableElevation size="large" type="submit">
                                        {signInLoading ? <CircularProgress size={24} color="primary" /> : <FM id="app.signin.signin_button" />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Container>
            <Dialog onClose={handleClose} open={openDialog} fullWidth maxWidth="sm">
                <DialogContent>
                    <FM id="app.signin.new_password_prompt" />
                <CustomTextField onChange={event => setNewPassword(event.target.value)} label={<FM id="app.signin.new_password" />} name="password" type="password" />
                { !!newPasswordFailure ? 
                <Alert severity="error">
                    {newPasswordFailure}
                </Alert> : <div/> }
                </DialogContent>
                <DialogActions>
                    <Button disabled={newPasswordLoading} onClick={handleNewPassword} color="primary" variant="contained" disableElevation size="large" >
                        { newPasswordLoading ? <CircularProgress size={24} color="primary" /> : <FM id="app.general.confirm_button" /> }
                    </Button>



                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

