import { React } from 'react';

// Material-UI Imports
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// Material-UI Icon Imports
import PublicIcon from '@material-ui/icons/Public';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Formik Imports
import { useField } from "formik";


export const ValidatedTextField = ({ label, disabled, type, multiline, rows, defaultValue, InputProps, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
        <TextField 
            fullWidth 
            variant="outlined" 
            type={type} 
            disabled={disabled} 
            multiline={multiline} 
            rows={rows}
            margin="dense" 
            label={label}
            helperText={errorText} 
            error={!!errorText} 
            defaultValue={defaultValue}
            InputProps={InputProps}
            {...field} 
        />
    )
}


export const ValidatedBinarySelectField = ({ label, true_state, false_state, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
            <TextField
                label={label}
                id="custom-select"
                {...field}
                helperText={errorText}
                error={!!errorText}
                select
                variant="outlined" margin="dense" fullWidth
                disabled={disabled}
        >
          <MenuItem value={true}>{true_state}</MenuItem>
          <MenuItem value={false}>{false_state}</MenuItem>
        </TextField>
    )
}


export const ValidatedSwitch = ({ label, ...props}) => {
    const [field] = useField(props);
    return (
        <FormControlLabel
        control={
          <Switch
            {...field}
            color="primary"
            checked={field.value}
          />
        }
        label={label}
      />
    )
};


export const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: 18,
      fontWeight: "bold",
    },
    body: {
      fontSize: 16,
    },
}))(TableCell);
  

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


export const GlobalStatusChip = ({ is_global }) => {
    if (is_global === true) {
        return (
            <Chip label="GLOBAL" style={{backgroundColor:'#c1f0ff'}} icon={<PublicIcon />} />
        );
    } else {
        return (
            <Chip label="TARGETED" style={{backgroundColor:'#ffe1c1'}} icon={<SupervisedUserCircleIcon />} />
        );
    }
}

export const PublishStatusChip = ({ is_published }) => {
    if (is_published === true) {
        return (
            <Chip label="PUBLISHED" style={{backgroundColor:'#c1f0ff'}} icon={<VisibilityIcon />} />
        );
    } else {
        return (
            <Chip label="HIDDEN" style={{backgroundColor:'#ffe1c1'}} icon={<VisibilityOffIcon />} />
        );
    }
}

const useTooltipStyles = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        boxShadow: theme.shadows[1],
        fontSize: 16,
        maxWidth: 400,
    },
}));

export const StyledTooltip = (props) => {
    const classes = useTooltipStyles();

    return (
        <Tooltip arrow classes={classes} {...props} />
    )
}
