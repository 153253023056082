import React, {useState, useEffect} from "react";
import { Route, Switch } from "react-router-dom";

import SignInPanel from "../cognito/SignInPanel";
import AdminPanel from "./AdminPanel";
import PrivateRoute from "./PrivateRoute";
import { useAuthContext } from "../AuthContext";

export default function Routes() {
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const { onPageLoad } = useAuthContext();

    useEffect(() => {
        onPageLoad()
            .finally((data) => {
                setIsAuthenticating(false);
            });
    }, [onPageLoad]);

    return (
        !isAuthenticating && (
            <Switch>
                <Route exact path="/login">
                    <SignInPanel />
                </Route>
                <PrivateRoute exact path="/" transfer="/login">
                    <AdminPanel />
                </PrivateRoute>
                <Route />
            </Switch>)
        );
};
