import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from "../AuthContext";

const PrivateRoute = ({children, transfer, ...rest}) => {
    const { isAuthenticated } = useAuthContext();
    return (
        <Route {...rest} render={() => (
            isAuthenticated ? <React.Fragment>{children}</React.Fragment> : <Redirect to={transfer} />
        )} />
    );
};

export default PrivateRoute;