import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// Custom Components
import FeatureStateEditMenu from './menus/feature_state_edit_menu/FeatureStateEditMenu';

export default function FeatureStateTableRow(props) {
    const {
        feature_name,
        is_available
    } = props;

    function createActionButtons() {
        return (
                <FeatureStateEditMenu {...props} />
        )
    }

    return (
        <TableRow key={feature_name}>

            <TableCell align="left">
                {feature_name}
            </TableCell>

            <TableCell align="left">
                {is_available}
            </TableCell>

            <TableCell align="center">
                {createActionButtons()}
            </TableCell>

        </TableRow>
    )
}

FeatureStateTableRow.propTypes = {
    feature_name: PropTypes.string.isRequired,
    is_available: PropTypes.number.isRequired
}