import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import { FormattedMessage as FM } from 'react-intl';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export default function TargetedLinkPanel() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return(
    <div className={classes.root}>
              <CssBaseline />

              <Container maxWidth="lg">

        <Paper>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label={<FM id = "app.targeted_link.menus.add.title" />} />
        <Tab label={<FM id = "app.targeted_link.menus.archive.title" />} />
      </Tabs>
    </Paper>
    <TabPanel value={value} index={0}>
        <Typography><FM id="app.targeted_link.menus.add.message"/></Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <Typography><FM id="app.targeted_link.menus.archive.message"/></Typography>
      </TabPanel>
      </Container>
    </div>
    );
}