import React from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { useHistory } from "react-router-dom";

import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import BuildIcon from '@material-ui/icons/Build';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PublicIcon from '@material-ui/icons/Public';

import config from 'config';
import { StyledTooltip } from "components/widgets/CustomComponent";
import { useAuthContext } from "components/AuthContext";



export default function Header(props) {
    const { isAuthenticated, username, signOut } = useAuthContext();
    const history = useHistory();

    const handleLogout = async () => {
        const { success, result, error } = await signOut();
        if (success) {
            history.push("/login");
            console.log('Logout Success:', result);
        } else {
            console.error('Logout Failed:', error);
        };
    };

    const createEnvironmentChip = () => {
        if (config.env === "dev") {
            return <Chip label="DEVELOPMENT" style={{backgroundColor:'#ffe1c1'}} icon={<BuildIcon />} />
        } else if (config.env === "prod") {
            return <Chip label="PRODUCTION" style={{backgroundColor:'#c1f0ff'}} icon={<PublicIcon />} />
        };
    };

    const createLabel = () => {
        if (isAuthenticated) {
            return username;
        }
        else if (window.location.pathname === "/login" || window.location.pathname === "/") {
            return <FM id = "app.signin.page_title" />;
        }
        else {
            return "404 not found";
        }
    }

    return (
        <AppBar position="static" color="primary">
            <Container maxWidth="md">
                <Toolbar>
                    <Typography variant="h6" color="inherit">
                        <strong>
                            <FM id = "app.title" />
                        </strong>
                    </Typography>
                    <Typography variant="h6" color="inherit" style={{ marginLeft: 'auto' }}>
                        { createLabel() }
                    </Typography>
                    { isAuthenticated &&
                    <StyledTooltip title={"Logout"}>
                        <IconButton color="default" onClick={handleLogout}>
                            <ExitToAppIcon />
                        </IconButton>
                    </StyledTooltip> }
                    <Typography variant="h4" style={{ align: 'right', marginLeft: 15, marginRight: 15, marginTop: 6}}>
                        |
                    </Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        { createEnvironmentChip() }
                    </div>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

