import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import BannerTableRow from './BannerTableRow';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));


export default function BannerTable(props) {
    const {
        rows
    } = props;
    const classes = useStyles();

    function createBannerTableRow(object) {
        return <BannerTableRow
            key={object.banner_id}
            banner_id={object.banner_id}
            title={object.title}
            description={object.description ? object.description : ""}
            image_url={object.image_url}
            target_url={object.target_url ? object.target_url : ""}
            start_dt={object.start_dt}
            end_dt={object.end_dt}
            active_status={object.active_status}
            is_published={object.is_published}
            is_global={object.is_global}
            priority={object.priority}
            show_before_start={object.show_before_start ? object.show_before_start : false}
            show_after_expiry={object.show_after_expiry ? object.show_after_expiry : false}
        />
    }

    return(
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell><FM id = "app.banner.attributes.image_preview" /></TableCell>
                        <TableCell align="left"><FM id = "app.banner.attributes.banner_id" /></TableCell>
                        <TableCell align="center"><FM id = "app.banner.attributes.priority" /></TableCell>
                        <TableCell align="center"><FM id = "app.banner.attributes.status" /></TableCell>
                        <TableCell align="center"><FM id = "app.banner.attributes.start_dt" /></TableCell>
                        <TableCell align="center"><FM id = "app.banner.attributes.end_dt" /></TableCell>
                        <TableCell align="center"><FM id = "app.banner.attributes.image_url" /></TableCell>
                        <TableCell align="center"><FM id = "app.banner.attributes.target_url" /></TableCell>
                        <TableCell align="center"><FM id = "app.general.actions" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(createBannerTableRow)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

BannerTable.propTypes = {
    rows: PropTypes.array.isRequired,
}