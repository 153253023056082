import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

// Material UI Icons
import PublicIcon from '@material-ui/icons/Public';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Custom Components
import SurveyArchiveMenu from './menus/survey_archive_menu/SurveyArchiveMenu';
import SurveyEditMenu from './menus/survey_edit_menu/SurveyEditMenu';
import SurveyPublishMenu from './menus/survey_publish_menu/SurveyPublishMenu';
import { createURLButton } from '../widgets/URLButton';
import { reformatDateString } from '../Utils';
import { StyledTooltip } from 'components/widgets/CustomComponent';

export default function SurveyTableRow(props) {
    const {
        survey_id,
        title,
        survey_url,
        start_dt,
        end_dt,
        is_global,
        is_published,
        priority,
        coin_award
    } = props;

    function createGlobalStatusIcon() {
        if (is_global === true) {
            return (
                <StyledTooltip title={<FM id="app.general.tooltips.global" />}>
                    <PublicIcon fontSize="small" />
                </StyledTooltip>
            )
        } else {
            return (
                <StyledTooltip title={<FM id="app.general.tooltips.targeted" />}>
                    <SupervisedUserCircleIcon fontSize="small" />
                </StyledTooltip>
            )
        }
    }
    function createPublishStatusIcon() {
        if (is_published === true) {
            return (
                <StyledTooltip title={<FM id="app.general.tooltips.published" />}>
                    <VisibilityIcon fontSize="small" />
                </StyledTooltip>
            )
        } else {
            return (
                <StyledTooltip title={<FM id="app.general.tooltips.hidden" />}>
                    <VisibilityOffIcon fontSize="small" />
                </StyledTooltip>

            )
        }
    }

    function createActionButtons() {
        return (
            <div>
                <SurveyPublishMenu {...props} />
                <SurveyEditMenu {...props} />
                <SurveyArchiveMenu {...props} />
            </div>
        )
    }

    return (
        <TableRow key={survey_id}>

            <TableCell component="th" scope="row" align="left">
                {survey_id}
            </TableCell>

            <TableCell align="left" style={{ width: 135 }}>
                {title}
            </TableCell>

            <TableCell align="center">
                {priority}
            </TableCell>

            <TableCell align="center">
                {createGlobalStatusIcon()} {createPublishStatusIcon()}
            </TableCell>

            <TableCell align="center">
                {reformatDateString(start_dt)}
            </TableCell>

            <TableCell align="center">
                {reformatDateString(end_dt)}
            </TableCell>

            <TableCell align="center">
                {coin_award}
            </TableCell>

            <TableCell align="center">
                {createURLButton(survey_url)}
            </TableCell>

            <TableCell align="center">
                {createActionButtons()}
            </TableCell>

        </TableRow>
    )
}

SurveyTableRow.propTypes = {
    survey_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    survey_url: PropTypes.string.isRequired,
    start_dt: PropTypes.string.isRequired,
    end_dt: PropTypes.string.isRequired,
    active_status: PropTypes.string.isRequired,
    is_global: PropTypes.bool.isRequired,
    is_published: PropTypes.bool.isRequired,
    priority: PropTypes.number.isRequired,
    coin_award: PropTypes.number.isRequired
}