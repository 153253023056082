import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

// Material UI Icons
import AddIcon from '@material-ui/icons/Add';

// Form Components
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, Field } from "formik";
import {  MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as yup from "yup";

// Custom Components
import {
    ValidatedTextField,
    StyledTableCell,
    StyledTableRow,
} from "components/widgets/CustomComponent";
import { createImageButton } from "components/widgets/ImageButton";
import { createURLButton } from "components/widgets/URLButton";
import { reformatDateString } from 'components/Utils';
import { useFeatureStateContext } from 'components/feature_state/FeatureStatePanel';

// AWS Imports
import { useAWSLambdaContext } from "components/AWSLambda";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
        fontWeight: 900,
        height: "100%",
    },
    stepsPaper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepper: {
        background: '#e6e6e6',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    div: {
        float: "right",
    }
}
));


export default function FeatureStateAddForm(props) {
    const {
        closeDialog
    } = props; 
    const classes = useStyles();

    const [success, setSuccess] = useState('');
    const [response, setResponse] = useState('');
    const [failure, setFailure] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        feature_name: "",
        is_available: 0
    });

    const { addFeatureState } = useAWSLambdaContext();
    const { handleFeatureStateFetch } = useFeatureStateContext();

    const handleFeatureStateAdd = async () => {
        handleNext();
        const {success, message, response, error } = await addFeatureState(formData);
        if (success) {
            setSuccess(message);
            setResponse(response);
        } else {
            setFailure(message);
            setResponse(error);
        };
        handleNext();
    };

    const getSteps = () => {
        return [<FM id="app.feature_state.add_feature_state" />, <FM id="app.feature_state.confirm_details" />, <FM id="app.general.result" />];
    };

    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFinish = () => {
        handleFeatureStateFetch();
        closeDialog();
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return createInputStep();
            case 1:
                return createConfirmStep();
            case 2:
                return createLoadingStep();
            case 3:
                return createResultStep();
            default:
                return 'Unknown Step';
        }
    };

    const validationSchema = yup.object({
        feature_name: yup
            .string()
            .required(<FM id="app.general.required"/>),
        is_available: yup
            .number()
            .integer(<FM id="app.general.integer_error"/>)
            .oneOf([0, 1], <FM id="app.feature_state.is_available_validation"/>)
            .required(<FM id="app.general.required"/>)
    })

    const createInputStep = () => {
        return(
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Formik
                    initialValues={formData}
                    onSubmit={(data) => {
                        setFormData(data);
                        handleNext();
                    }}
                    validationSchema={validationSchema}
                >
                    {({ values, errors }) => (
                    <Form autoComplete="off">
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ValidatedTextField label={<FM id="app.feature_state.attributes.feature_name" />} name="feature_name" type="input" />
                            </Grid>
                            <Grid item xs={6}>
                                <ValidatedTextField label={<FM id="app.feature_state.attributes.is_available" />} name="is_available" type="number" InputProps={{inputProps: { max: 1, min: 0}}}/>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button className={classes.button} onClick={closeDialog} color="default" variant="outlined" disableElevation size="large">
                                <FM id="app.general.cancel_button" />
                            </Button>
                            <Button className={classes.button} type="submit" color="primary" variant="contained" disableElevation size="large" >
                                <FM id="app.feature_state.registration" />
                            </Button>
                        </DialogActions>
                    </Form>
                    )}
                </Formik>
            </MuiPickersUtilsProvider>
        );
    }

    const createRow = (attribute_name, value) => {
        return {attribute_name, value};
    }

    const rows = [
        createRow(<FM id="app.feature_state.attributes.feature_name" />, formData.feature_name),
        createRow(<FM id="app.feature_state.attributes.is_available" />, formData.is_available)
    ];

    const createConfirmStep = () => {
        return(
            <div>
                <TableContainer component={Paper} variant="outlined">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><FM id="app.general.attribute_name" /></StyledTableCell>
                                <StyledTableCell align="right"><FM id="app.general.value" /></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                            <StyledTableRow key={row.attribute_name}>
                                <StyledTableCell component="th" scope="row">
                                    <b>{row.attribute_name}</b>
                                </StyledTableCell>
                                <StyledTableCell alignItems="center" align="right">
                                    {row.value}
                                </StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogActions>
                    <Button className={classes.button} onClick={handleBack} color="default" variant="outlined" disableElevation size="large">
                        <FM id="app.general.back_button" />
                    </Button>
                    <Button className={classes.button} startIcon={<AddIcon />} onClick={handleFeatureStateAdd} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.feature_state.add_feature_state" />
                    </Button>
                </DialogActions>
            </div>
    
        )
    };

    const createLoadingStep = () => {
        return (
            <div>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="stretch"
            >
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}
                        ><CircularProgress color="primary" />
                    </Paper>
                </Grid>
            
            </Grid>
             <DialogActions>
             <Button disabled className={classes.button} color="primary" variant="contained" disableElevation size="large" >
                 <FM id="app.general.finish_button" />
             </Button>
         </DialogActions>
         </div>
        );
    };

    const createResultStep = () => {
        return(
            <div>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        { success ? 
                        <Alert severity="success">
                            <AlertTitle>{success}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                        { failure ? 
                        <Alert severity="error">
                            <AlertTitle>{failure}</AlertTitle>
                            Response Data: {response}
                        </Alert> : <div/> }
                    </Grid>
                    {success ? <Grid item xs={12}> </Grid> : <div/> }
                </Grid>
                <DialogActions>
                    <Button className={classes.button} onClick={handleFinish} color="primary" variant="contained" disableElevation size="large" >
                        <FM id="app.general.finish_button" />
                    </Button>
                </DialogActions>
            </div>
        )
    };

    return (
        <div>
            <Paper variant='outlined' className={classes.stepsPaper}>
                <Stepper className={classes.stepper} activeStep={activeStep}>
                    { steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    }) }
                </Stepper>
            </Paper>
            {getStepContent(activeStep)}
        </div>
    );
}

FeatureStateAddForm.propTypes = {
    closeDialog: PropTypes.func.isRequired
}