import moment from 'moment-with-locales-es6';

function reformatDateString(date) {
    return(
      <span>
        <strong>{moment(date).format("YYYY/MM/DD")}</strong>
        <br/>
        {moment(date).format("HH:mm")}
      </span>
    )
  }

const extractDate = (datetime) => {
  return(datetime ? moment(datetime).format("YYYY/MM/DD[T]HH:mm") : null);
}

function setFlag(object) {
  var code;
    if (object.banner_code)
      code = object.banner_code;
    if (object.survey_code)
      code = object.survey_code;
    var published = true;
    var global = true;
    if (code === "PUBLISHED_GLOBAL") {
        published = true;
        global = true;
    }
    else if (code === "HIDDEN_GLOBAL") {
        published = false;
        global = true;
    }
    else if (code === "HIDDEN_TARGETED") {
        published = false;
        global = false;
    }
    else if (code === "PUBLISHED_TARGETED") {
        published = true;
        global = false;
    }
    object.published = published;
    object.global = global;
    return object
  }

function setFlags(list) {
    var output = list.map(setFlag);
    return output
  }

function isEmpty(obj) {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
  

export {reformatDateString, setFlags, extractDate, isEmpty};
