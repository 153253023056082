import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import English from 'lang/en.json';
import Japanese from 'lang/jp.json';

export const LanguageContext = React.createContext();

const local = navigator.language.substring(0, 2);

let lang;
if (local === 'en') {
  lang = English;
} else {
  lang = Japanese;
}

const flattenMessages = ((nestedMessages, prefix = '') => {
    if (nestedMessages === null) {
      return {}
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
      const value       = nestedMessages[key]
      const prefixedKey = prefix ? `${prefix}.${key}` : key
  
      if (typeof value === 'string') {
        Object.assign(messages, { [prefixedKey]: value })
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey))
      }
      return messages
    }, {})
  })

const LanguageWrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLanguage(lang) {
      const newLocale = lang;
      setLocale(newLocale);
      if (newLocale === 'en') {
        setMessages(English);
      } else {
        if (newLocale === 'jp'){
          setMessages(Japanese);
        } 
      }
    }
    return (
      <LanguageContext.Provider value = {{locale, selectLanguage}}>
        <IntlProvider messages={flattenMessages(messages)} locale={locale}>
          {props.children}
        </IntlProvider>
      </LanguageContext.Provider>
    );
 }

 export default LanguageWrapper;