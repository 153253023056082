import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage as FM } from 'react-intl';

// Material UI Imports
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import SurveyTableRow from './SurveyTableRow';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
}));


export default function SurveyTable(props) {
    const {
        rows
    } = props;
    const classes = useStyles();

    function createSurveyTableRow(object) {
        return <SurveyTableRow
            key={object.survey_id}
            survey_id={object.survey_id}
            title={object.title}
            description={object.description ? object.description : ""}
            survey_url={object.survey_url}
            coin_award={object.coin_award}
            start_dt={object.start_dt}
            end_dt={object.end_dt}
            active_status={object.active_status}
            is_published={object.is_published}
            is_global={object.is_global}
            priority={object.priority}
        />
    }

    return(
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><FM id = "app.survey.attributes.survey_id" /></TableCell>
                        <TableCell align="left"><FM id = "app.survey.attributes.survey_title" /></TableCell>
                        <TableCell align="center"><FM id = "app.survey.attributes.priority" /></TableCell>
                        <TableCell align="center"><FM id = "app.survey.attributes.status" /></TableCell>
                        <TableCell align="center"><FM id = "app.survey.attributes.start_dt" /></TableCell>
                        <TableCell align="center"><FM id = "app.survey.attributes.end_dt" /></TableCell>
                        <TableCell align="center"><FM id = "app.survey.attributes.coin_award" /></TableCell>
                        <TableCell align="center"><FM id = "app.survey.attributes.survey_url" /></TableCell>
                        <TableCell align="center"><FM id = "app.general.actions" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(createSurveyTableRow)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

SurveyTable.propTypes = {
    rows: PropTypes.array.isRequired,
}